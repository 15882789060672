<template>
  <p class="statusheader">{{ $t('payment.paid.label') }}</p>
  <div class="status">
    <div v-if="provider === 'TINK'" class="statustext medium">
      {{ $t('payment.paid.tink.text', { date: dateFormat(payment.updatedTimestamp) })}}
    </div>
    <div v-if="provider === 'SWISH' || provider === 'VIPPS'" class="statustext medium">
      {{ $t('payment.paid.direct.text', { date: dateFormat(payment.updatedTimestamp) })}}
    </div>
    <div v-if="provider === 'TINK'" class="statuslabel">
      <p>{{ $t('payment.paid.tink.status') }}</p>
    </div>
    <div v-if="provider === 'SWISH' || provider === 'VIPPS'" class="statuslabel">
      <p>{{ $t('payment.paid.direct.status') }}</p>
    </div>
  </div>
</template>

<script>
  import { formatDate } from "../services/dateService";

  export default {
    name: 'StatusInfo',
    props: ['payment', 'provider'],
    methods: {
      dateFormat(value) {
        return formatDate(value, this.$t);
      },

    }
  }
</script>

<style scoped>

.statusheader {
    font-size: 16px;
    font-weight: 400;
    color: #5e554a;
    margin: 0;
    /* margin-bottom: -3px; */
    padding: 0;
  }

  .status {
    display: flex;
    width: 100%;
  }

  .statustext {
    text-align: left;
  }

  .statuslabel {
    text-align: right;
    display: inline;
    margin-right: 0;
    margin-left: auto;
  }

  .statuslabel>p {
    background-color: #e8f9ef;
    color: #0d234b;
    padding: 0 0.6rem 0 0.6rem;
    margin-top: 0;
    font-size: 1.2rem;
    line-height: 2;
  }

</style>