<template>
    <div class="top-bar">

        <div class="container">
            <div class="img-container">
                <img class="pn-logo logo" alt="PostNord Strålfors" src="../assets/pnsf-logo-color.svg" />
            </div>
            <div class="mPay">Mobile Payment</div>
        </div>
    </div>
    <div class="header">
        <svg xmlns:xlink="http://www.w3.org/1999/xlink" role="presentation" aria-hidden="true" class="cover"
            viewBox="0 0 600 560" fill="#fff">
            <path d="M0,0 L600,0 A280,280,0,0,0,600,560 L0,560 A280,280,0,0,0,0,0"></path>
        </svg>
    </div>
    <div class="main">
        <h1 class="pn-xl">Mobile Payment Services</h1>
        <div class="text">
            PostNord Strålfors offers several services for payments to our customers. These services are used by our
            customers to send out invoices, with links for payment with QR-code on physical mailings, or digitally via
            e.g. SMS, e-Mail.
        </div>
        <h3 class="pn-m">
            Account to Account (A2A) payment via QR-code on printed invoice or link in SMS or e-Mail messages
        </h3>
        <div class="text">
            This service, where the payment is completed as an internet bank payment, is offered through our partner
            Tink. When the recipient is using this payment method they also agree to Tink terms that is referred do
            during the invoice payment process. These payment inititations can be scheduled for payment the same day or
            a future date, e.g. om due date.
        </div>
        <div class="text">
            If you have questions or issues regarding the payment, please contact your supplier specified on your invoice.
        </div>
        <div class="text">
            <p>
                <a alt="länk till strålfors websidan"
                    href="https://www.stralfors.se/vara-losningar/payment-management/betaltjanster" target="_blank">More information
                    regarding Postnord Strålfors services</a>
            </p>
            <p>
                <a alt="link to tinks website" href="https://tink.com/products/payment-initiation/" target="_blank">More information
                    regarding payment with Tink</a>
            </p>
        </div>
    </div>
    <div class="footer">
        <svg xmlns:xlink="http://www.w3.org/1999/xlink" role="presentation" aria-hidden="true"
            style="background: #FFFFFF;" class="page-footer-shape" viewBox="0 0 1680 126">
            <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"
                d="M0 124.425C265.751 43.517 547.794 0 840 0c292.21 0 574.25 43.517 840 124.425V126H0v-1.575z"
                fill="#F3F2F2"></path>
        </svg>
        <div class="page-footer-postnord">
            <div class="market-info grid-cell">
                <div class="page-footer-column">
                    <h2 class="page-footer-column-headline">Our markets</h2>
                    <div class="page-footer-column-content">
                        <p><a href="https://www.stralfors.dk/" class="countries" target="_blank">Denmark</a> <br><a
                                href="https://www.stralfors.no/" class="countries" target="_blank"
                                rel="noopener">Norway</a><br><a href="https://www.stralfors.fi/" class="countries"
                                target="_blank" rel="noopener">Finland</a><br><a href="https://www.stralfors.se/"
                                target="_blank" class="countries">Sweden</a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="page-footer-column follow">
                <h2 class="page-footer-column-headline">Follow us</h2>
                <div class="page-footer-column-content">
                    <div class="page-footer-column-content-social-media-icon-container">

                        <a href="https://www.linkedin.com/company/stralfors/"
                            aria-label="Följ Postnord Strålfors på LinkedIn" class="page-footer-column-content-social"
                            target="_blank">
                            <span class="icon icon-linkedin"></span>
                        </a>
                        <a href="https://vimeo.com/channels/stralforssverige"
                            aria-label="Följ Postnord Strålfors på Vimeo" class="page-footer-column-content-social"
                            target="_blank">
                            <span class="icon icon-vimeo-square"></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-content">
            <div class="page-footer-links-container">
                <div class="row1">
                    <a class="page-footer-copyright">© PostNord Strålfors Sverige AB</a>
                </div>
            </div>
        </div>
        <div class="footer-img">
            <img class="pn-logo logo" alt="PostNord Strålfors" src="../assets/pnsf-logo-color.svg" />
        </div>
    </div>
</template>
<script>
export default {
    name: "InformationPage",
};
</script>

<style scoped>
.follow {
    margin-top: 2em;
}

.grid__cell {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
}

a.page-footer-column-content-social-media-icon-container {
    display: flex;
    flex-wrap: wrap;
}

.row1 .row2 {
    display: inline-flex;
}

.row2 {
    margin-top: 1.5em;
}

.right {
    margin-left: 1.5em;
}

.page-footer-links-container {
    text-align: center;
    white-space: no-wrap;
    font-size: 1.4rem;
}

.page-footer-copyright {
    color: black;
}

.countries {
    text-decoration: none;
    line-height: 2.5em;
}

span {
    height: 4rem;
    width: 4rem;
}

.page-footer-postnord {
    margin: 0 auto;
    padding: 0 1.5em 1em;
    max-width: 30em;
    text-align: left;
}

.icon-vimeo-square {
    content: url("../assets/vimeo.png");
}

.icon-linkedin {
    content: url("../assets/Linkedin.png");
    margin-right: 0.8rem;
}

.icon {
    box-sizing: border-box;
}

.text {
    margin-top: 2em;
}

.header {
    position: relative;
    background: url("../assets/Measure-parcel-shipment.jpg") no-repeat left 25%;
    background-size: cover;
    height: 35em;
}

.top-bar {
    margin-top: 0.5em;

}

.header .cover {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
}

.footer {
    display: block;
}

.container {
    display: flex;
    max-width: 100%;
}

.img-container {
    width: 50%;
    text-align: right;
}

.logo {
    width: 10em;
    padding-right: 1em;
}

.mPay {
    text-align: left;
    max-width: 50%;
    color: #0D234B;
    font-weight: 500;
    font-size: 1.75em;
    line-height: 36px;
}

.footer-img {
    text-align: center;
    background-color: #F3F2F2;
    margin-top: 0;
    padding-bottom: 2em;
}

.footer {
    background-color: #F3F2F2;
}

.footer-content {
    margin: 2em 1.5em;
    padding: 2em 2em;
    border-bottom: solid 1px #D3CECB;
    border-top: solid 1px #D3CECB;
}

.page-footer-shape {
    display: block;
}

.page-footer-column-headline {
    margin-top: 0 !important;
}

@media only screen and (max-width: 1080px) {

    /* For mobile phones: */
    .market-info {
        margin-top: 2em;
    }

    .header {

        height: 14.375em;
    }

    .header .cover {
        position: absolute;
        height: 100%;
        top: 0;
        right: 50;
    }
}

@media screen and (max-width: 480px) {
    .market-info {
        margin-top: 2em;
    }

    .header {
        background-position: center;
        height: 14.375em;
    }

    .header .cover {
        position: absolute;
        height: 100%;
        top: 0;
        right: 50;
    }
}
</style>