import { createApp } from 'vue'
import App from './App.vue'
import VueHotjar from 'vue-hotjar-next'
import router from './router'

import { createI18n } from 'vue-i18n';

import 'pn-design-assets/pn-assets/styles/pn-styles.scss'
import './main.css'

import { messages } from './messages'

import { defineCustomElements } from '@postnord/web-components/loader';
defineCustomElements();

const i18n = createI18n({
    locale: 'EN',
    fallbackLocale: 'EN',
    messages
});

const app = createApp(App);
app.config.compilerOptions.isCustomElement = (tag) => {
    return tag.startWith("pn-");
}
app.use(router)
  .use(VueHotjar, {
  id: 4989208,
  isProduction: window.paymentConfig.environmentType === 'prod',
  snippetVersion: 6})
  .use(i18n)
  .mount('#app');
