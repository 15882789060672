<template>
    <div class="language-selector" :data-value="value" :data-list="list">
        <div class="selector" @click="toggle()">

            <pn-icon symbol="globe" small="false" color="blue700" />
            <div class="label medium">
                <span>{{ value }}</span>
            </div>

            <div class="globe" :class="{ 'hidden': !visible, 'visible' : visible }">
                <ul class="ul-class">
                    <li class="li-class" :class="{ current: item.name === value }" v-for="item in list" :key="item.id" @click="select(item)">
                        {{ item.name
                        }}</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { BASE_URLS } from '../services/urlService';

export default {
    name: 'language-selector',
    props: ['language', 'id', 'token'],
    emits: ['closeInformation'],
    data() {
        return {
            list: [{ "id": "SE", "name": "Svenska" }, { "id": "EN", "name": "English" }, { "id": "NO", "name": "Norsk" }, { "id": "FI", "name": "Suomi" }, { "id": "DK", "name": "Dansk" }],
            value: "",
            visible: false,
            error: null
        }

    },
    mounted() {
        this.value = this.fetchLang(this.language)
    },
    methods: {
        fetchLang(lang) {
            let search = this.list.find((o) => o.id === lang);
            console.log(search);

            return search !== undefined ? search.name : "English";
        },
        getRequestConfig() {
            if (!this.token) {
                return undefined;
            }
            return {
                headers: { 'Authorization': 'Bearer ' + this.token }
            };
        },
        toggle() {
            this.visible = !this.visible;
        },
        select(option) {
            this.value = option.name;
            this.$root.$i18n.locale = option.id;
            let data = {
                language: option.id
            };
            axios.put(`${BASE_URLS.PROCESSOR}/v4/payment/${this.id}/language`, data, this.getRequestConfig())
                .catch(err => {
                    this.error = err.response.data.compositeFault.faults[0].faultCode;
                });
        }

    }
};

</script>
<style scoped>
pn-icon {
    float: right;
    padding: 0.3em 0.5em 0.3em 0em;
}

.language-selector {
    margin-top: 1em;
    width: 7em;

    .selector {
        border-radius: 2em;
        font-weight: 400;
        color: #005d92;

        border: 1px solid #005d92;
        background: #F8F8F8;
        position: relative;
        z-index: 1;


        .label {
            display: block;
            padding: 0.3em 0em 0.3em 0.6em;
            color: #005d92;
        }
    }

    .ul-class {
        width: 100%;
        list-style-type: none;
        padding: 0;
        margin: 0;
        font-size: 16px;
        border: 1px solid gainsboro;
        position: absolute;
        z-index: 1;
        background: #fff;
    }

    .li-class {
        padding: 0.5em;

        &:hover {
            color: #0d234b;
            background: #e0f8ff;
        }
    }

    &:focus-visible {
        outline: 1px solid #0d234b;
    }

    .current {
        background: #eaeaea;
    }

    .hidden {
        visibility: hidden;
        opacity: 0;
    }

    .visible {
        visibility: visible;
        opacity: 1;
    }
}
</style>
