<template>
    <div class="slider">
        <div class="text">
            <div class="title">
                {{ $t('slider.title') }}<br>
            </div>
        </div>
        <div class="slider-container">
            <div class="top-slider-container" :style="'width:' + sliderWidth + 'px;'">
                <div class="top-slider">
                    <div class="top-slider-fill"
                        :style="'width:' + (sliderValue - this.min) / (this.max - this.min) * (sliderWidth - 16) + 'px;'">
                    </div>
                </div>
                <div class="top-slider-thumb"
                    :style="'left:' + (sliderValue - this.min) / (this.max - this.min) * (sliderWidth - 16) + 'px;'">
                </div>
            </div>
            <input class="bottom-slider" ref="slider" type="range" :min="this.min" :max="this.max" @input="sliderInput">
        </div>
        <div class="min-max" :style="'width:' + sliderWidth + 'px;'">
            <span class="min">{{ this.min }}</span><span class="max">
                {{ this.max }}</span>
        </div>

        <div class="numerical">
            <div class="description">
                {{ $t('slider.anyAmount') }} {{ this.min }} {{ $t('slider.and') }} {{ this.max }} {{ currency }}
            </div>
            <input class="numerical-input" type="number" v-model="this.sliderText" :min="this.min" :max="this.max"
                :step="this.step" @change="onTextChange"> {{ currency }}
        </div>
    </div>
    <pn-toast appearance="info" closable="false" icon="alert-info-circle" v-show="invalidInput">
        {{ $t(this.errorText) }}
    </pn-toast>
</template>

<script>

export default {
    name: 'AmountSlider',
    props: ['amounts', 'value', 'currency'],
    emits: ["amount"],
    data() {
        return {
            max: null,
            min: null,
            showMin: null,
            sliderText: null,
            sliderValue: null,
            sliderWidth: null,
            step: null,
            middle: null,
            invalidInput: false,
            errorText: "",
        };
    },
    mounted() {
        this.max = Math.max(...this.amounts);
        this.min = Math.min(...this.amounts);
        this.middle = this.amounts[1];
        this.sliderValue = this.value || this.min;

        this.step = this.setStep();
        this.sliderText = this.sliderValue.toFixed(2).replace(/\.00/, '');

        setTimeout(() => {
            if (!this.$refs.input) {
                return;
            }
            this.$refs.input.value = this.sliderValue;
        });
        this.$emit('amount', this.sliderValue);

        window.requestAnimationFrame(this.updateSliderWidth);

        window.addEventListener('resize', () => {
            window.requestAnimationFrame(this.updateSliderWidth);
        });
    },
    methods: {
        setStep() {
            const range = this.max - this.min;
            if (range <= 250) {
                return 5;
            }
            if (range <= 500) {
                return 10;
            }
            if (range <= 1000) {
                return 50;
            }
            if (range <= 2500) {
                return 100;
            }
            if (range <= 5000) {
                return 250;
            }
            if (range <= 1000) {
                return 500;
            }
            if (this.max - this.min > 1000) {
                return 1000;
            }
        },
        sliderInput(e) {
            const val = parseFloat(e.target.value);
            const invalid = this.checkAmount(val);
            if (!invalid) {
                const first = this.amounts.map((x) => ({ val: x, diff: Math.abs(val - x) })).sort((a, b) => a.diff - b.diff)[0];
                this.sliderValue = first.val;
                this.sliderText = this.sliderValue.toFixed(2).replace(/\.00/, '');
                this.$emit('amount', this.sliderValue);
            }
            else {
                return;
            }
        },
        updateSliderWidth() {
            const slider = this.$refs.slider;
            if (!slider) {
                return;
            }
            this.sliderWidth = slider.offsetWidth;
        },
        onTextChange(e) {
            let val = parseFloat(e.target.value);
            var invalid = this.checkAmount(val);

            if (!invalid) {
                this.sliderText = val.toFixed(2).replace(/\.00/, '');
                this.sliderValue = parseFloat(this.sliderText);
                this.$emit('amount', this.sliderValue);
            } else {
                return;
            } 
        },
        checkAmount(v) {
            if (!v || isNaN(v)) {
                this.invalidInput = true;
                this.errorText = "error.validation.amountInvalid";
                this.$refs.input.value = this.min;
                return;
            }
            if (v < this.min) {
                this.invalidInput = true;
                this.errorText = "error.validation.amountTooLow";
                this.$refs.input.value = this.min;
                return;
            }
            if (v > this.max) {
                this.invalidInput = true;
                this.errorText = "error.validation.amountTooHigh";
                this.$refs.input.value = this.max;
                return;
            }
            this.invalidInput = false;
        }

    },
    unmounted() {
        window.removeEventListener;
    }
}
</script>

<style scoped>
.slider {
    flex-direction: row;
    margin: 1em auto 1em auto;
    padding: 1em 1.5em 1em 1.5em;
    width: 100%;
    border: 2px solid #005d92;;
    border-radius: 0.5em;
    text-align: left;
}

.slider-container {
    position: relative;
}

.top-slider-container {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}

.top-slider {
    position: absolute;
    opacity: 1;
    overflow: hidden;
    top: 4px;
    width: 100%;
    height: 8px;
    background: #f3f2f2;
    border: 1px solid #969087;
    border-radius: 4px;
}

.top-slider-fill {
    position: absolute;
    height: 100%;
    background-color: #005d92;
}

.top-slider-thumb {
    top: 0;
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #005d92;
}

.bottom-slider {
    opacity: 0;
    margin: 0;
    width: 100%
}

.min-max {
    display: flex;
    justify-content: space-between;
    /* padding-bottom: 1em; */
}

.min {
    margin-top: -0.5em;
}

.max {
    margin-top: -0.5em;
}



.numerical {
    margin-top: 1em;
}

.numerical-input {
    width: 33%;
    left: 0;
}

.description,
.title {
    margin-bottom: 0.5em;
}

.title {
    font-size: 1.6rem;
    font-weight: 500;
}

.description {
    font-size: 1.4rem;
    font-weight: 400;

}
</style>

