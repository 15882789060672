<template>
  <div class="brandingContainer">
    <div class="branding">
    <span>{{ $t('mobilePayment.poweredBy') }}</span> <img class="pn" :alt="$t('mobilePayment.imgAlt')" src="../assets/pnsf-logo-color.svg" />
    </div>
    <a class="mpay" :alt="$t('mobilePayment.alt')" href="/about" target="_blank">{{ $t('mobilePayment.text') }}</a>
  </div>
</template>

<script>
  export default {
    name: 'BrandingInfo'
  }
</script>

<style scoped>

  .brandingContainer {
    position: relative;
    bottom: 0;
    margin-top: 3em;
  }

  .branding {
    text-align: left;
    color: #5e554a;
    font-size: 14px;
  }

  .pn {
    width: 91.93px;
    vertical-align: text-top;
    margin-bottom: 0.5em;
  }

  .mpay {
    text-align: left;
    font-size: 1.4rem;
  }

</style>

<script>

</script>
