<template>
  <div class="autogiro">
    <div class="container">
      <div class="autogiroText">
        <h3 class="pn-m bold">{{ $t('autogiro.header') }}</h3>
        <span>{{ $t('autogiro.welcome.text') }}</span>
      </div>
      <pn-button class="buttonwrapper" @click="autoGiroStartClickHandler">
        {{ $t('autogiro.start.normal') }}
      </pn-button>
    </div>
  </div>
</template>

<script>

export default {
  props: ["paymentId"],
  methods: {
    autoGiroStartClickHandler() {
      if(window.location.host.includes("mpay.stralfors.com")) {
        window.location.href = "https://" + window.location.host + '/autogiro/payment/' + this.paymentId;
      }
      else if(window.location.host.includes("test.mpay.stralfors.com")) {
        window.location.href = "https://" + window.location.host + '/autogiro/payment/' + this.paymentId;
      }
      else if(window.location.host.includes("lby.infra.stralfors.com")) {
        window.location.href = "https://" + window.location.host + "/autogiro/payment/" + this.paymentId;
      }
      else {
        window.location.href = "http://" + window.location.host.replace("3000", "3011") + '/autogiro/payment/' + this.paymentId;
      }
    }
  },
}

</script>

<style scoped>
.autogiro {
  margin: 4.5rem auto auto auto;
  width: 100%;
  border-radius: 0.5em;
  background-color: #e0f8ff;
}

.container {
  display: flex;
  flex-direction: column;
  line-height: normal;
  text-align: left;
  margin: 0 2em;
}

.method span {
  margin-left: 0.5rem;
}

.buttonwrapper {
  margin: 1em 0;
}

pn-button {
  width: 100%;
}

.autogiroText {
  text-align: left;
  padding-bottom: 1em;
  border-bottom: 1px solid #8eddf9;
  width: 100%;
}

pn-toast {
  width: 100%;
  margin-top: 2rem;
}
</style>
