<template>

  <div class="spinner spinner-full main">
    <pn-spinner></pn-spinner>
    <div v-if="ongoing" data-testid="ongoingPayment" class="ongoingPaymentContainer  ">
      <h3>
        {{ $t(headerKey) }}
      </h3>
      <p>
        <a v-if="link" ref="paymentLink" :href="link">{{ $t(linkKey) }}</a>
      </p>
      <p v-if="qr">
        <img :src="qr">
      </p>
      <p>
        <a href="" @click="abort">Avbryt</a>
      </p>
    </div>
    <slot></slot>
  </div>

</template>

<script>

import axios from "axios";
import hashService from "../services/hashService";
import {BASE_URLS} from '../services/urlService';

export default {
  props: ['token', 'id', 'hideOngoing', 'initPayment'],
  emits: ['data', 'error', 'aborted'],
  name: 'PaymentLoader',
  data() {
    return {
      payment: null,
      headerKey: null,
      linkKey: null,
      ongoing: null,
      qr: null,
      qrLink: null,
      link: null,
      isAborted: false,
    }
  },
  mounted() {

    let promise = null;
    this.headerKey = 'payment.ongoing.header.{0}.{1}';
    this.linkKey = 'payment.ongoing.link.{0}';

    let waitForOngoing = false;
    let ongoingReceived = false;


    /**
     * If we already have a payment loaded, this means that we have triggered
     * the component manually. No need to load payment again, just wait
     * until the ongoing payment is finished
     *
     * We will have slightly different texts if we trigger it with
     * an already loaded payment
     */

    if (this.initPayment) {
      this.headerKey = 'payment.init.header.{0}.{1}';
      this.linkKey = 'payment.init.link.{0}';
      promise = Promise.resolve({data: this.initPayment});
      waitForOngoing = true;
    } else if (this.$route.name === 'paymentInitRef') {
      promise = axios.get(`${BASE_URLS.PROCESSOR}/v4/payment/reference/${this.$route.params.id}`, this.getRequestConfig());
    } else {
      promise = axios.get(`${BASE_URLS.PROCESSOR}/v4/payment/${this.id}`, this.getRequestConfig());
    }

    promise.then((response) => {

      const data = response.data;

      this.payment = data;
      this.$i18n.locale = (this.payment.language && this.payment.language !== 'NOT_SET') ? this.payment.language : this.payment.market; //Changed from market to language
      this.processPayment(data);

      if (!data.ongoingPayment && !waitForOngoing) {
        return {data};
      }

      this.$emit('data', {
        done: false,
        aborted: this.isAborted,
        data
      });

      this.ongoing = data.ongoingPayment;
      this.processPayment(data);

      return new Promise((resolve) => {

        const source = new EventSource(`${BASE_URLS.PROCESSOR}/v4/payment/${this.payment.paymentId}/events`);

        source.addEventListener('message', e => {
          const json = JSON.parse(e.data);

          if (json.type != 'DATA') {
            return;
          }

          const d = json.data;

          /**
           * Skip old events that has a lower version number
           */
          if (d.version <= data.version) {
            return;
          }

          if (d.ongoingPayment) {
            this.ongoing = d.ongoingPayment;
            data.ongoingPayment = d.ongoingPayment;
            this.processPayment(data);
            ongoingReceived = true;
            this.$emit('data', {
              done: false,
              aborted: this.isAborted,
              data
            });
            return;
          } else if (waitForOngoing && !ongoingReceived) {
            return;
          }

          // the payment is finished with success/error
          source.close();

          // ongoing payment should be null
          data.ongoingPayment = null;

          if (d.error) {
            resolve({data, error: d.error});
            return;
          }

          data.sourceAccountNumber = d.source;
          data.updatedTimestamp = d.timestamp;
          data.status = d.status;

          resolve({data});
        });

      });

    }).then(({data, error}) => {
      this.$emit('data', {
        done: true,
        aborted: this.isAborted,
        data,
        error
      });
    }).catch(err => {
      this.$emit('error', err);
    });
  },
  methods: {
    getRequestConfig() {
      if (!this.token) {
        return;
      }
      return {
        headers: {'Authorization': 'Bearer ' + this.token}
      };
    },
    processPayment(data) {

      if (!data.ongoingPayment) {
        return;
      }

      let providerName;
      if (data.ongoingPayment.type.toLowerCase() === "vipps" && (this.payment.market === "DK" || this.payment.market === "FI")) {
        providerName = "mobilePay";
      } else {
        providerName = data.ongoingPayment.type.toLowerCase();
      }
      this.headerKey = this.headerKey.replace(/\{0\}/, providerName);
      this.linkKey = this.linkKey.replace(/\{0\}/, providerName);

      this.link = data.ongoingPayment.link;

      if (data.ongoingPayment.type === 'VIPPS') {
        const selection = hashService.get(this.$route.hash, 'vipps');
        if (selection === 'QR')
          this.headerKey = this.headerKey.replace(/\{1\}/, 'qr');
        this.qr = data.ongoingPayment.qrLink;
      }

      if (data.ongoingPayment.type === 'SWISH') {

        const selection = hashService.get(this.$route.hash, 'swish');
        // redirect if selection is 'open_app'
        if (selection === 'open_app') {
          this.headerKey = this.headerKey.replace(/\{1\}/, 'link');
          if (this.initPayment) {
            window.location.href = this.link;
          }
          return;
        }

        if (selection === 'show_qr') {
          this.headerKey = this.headerKey.replace(/\{1\}/, 'qr');
          this.link = null;
          this.qr = data.ongoingPayment.qrLink;
          return;
        }

        this.headerKey = this.headerKey.replace(/\{1\}/, 'unknown');
        this.initSwishQr(data.ongoingPayment.token);

      } else {
        this.headerKey = this.headerKey.replace(/\{1\}/, 'link');
      }

    },
    initSwishQr(token) {
      this.qr = {
        loading: true,
        promise: axios.post(`${BASE_URLS.QR}/qr/swish/commerce/internal`, {
          token: token,
          format: 'png',
          size: 300,
          border: 1
        }, {
          ...this.getRequestConfig(),
          responseType: 'arraybuffer'
        }).then(response => {
          let image = btoa(
              new Uint8Array(response.data)
                  .reduce((data, byte) => data + String.fromCharCode(byte), '')
          );
          return `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;
        }).then(img => {
          this.qr.loading = false;
          this.qr.image = img;
        })
      }
    },
    abort(e) {
      this.isAborted = true;
      e.preventDefault();
      let baseUrl = null;
      if (this.ongoing.type === 'SWISH') {
        baseUrl = `${BASE_URLS.SWISH}/v3/swish`;
      } else if (this.ongoing.type === 'TINK') {
        baseUrl = `${BASE_URLS.TINK}/v3/tink`;
      } else if (this.ongoing.type === 'VIPPS') {
        baseUrl = `${BASE_URLS.VIPPS}/v1/vipps`;
      }

      axios.delete(`${baseUrl}/${this.ongoing.id}`, this.getRequestConfig());


    }
  }


}

</script>

<style scoped>

.ongoingPaymentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

</style>
