
const config = window.paymentConfig || {};

const BASE_URLS = config.mock ? {

    PROCESSOR: 'http://localhost:' + config.mock,
    OPTIN: 'http://localhost:' + config.mock,
    TINK: 'http://localhost:' + config.mock,
    SWISH: 'http://localhost:' + config.mock,
    VIPPS: 'http://localhost:' + config.mock,
    LOGIN: 'http://localhost:' + config.mock,
    QR: 'http://localhost:' + config.mock

} : {

    PROCESSOR: config.backendBaseUrl || '/api',
    OPTIN: config.optInBaseUrl || '/api',
    TINK: config.tinkBaseUrl || '/api',
    SWISH: config.swishBaseUrl || '/api',
    VIPPS: config.vippsBaseUrl || '/api',
    LOGIN: config.loginUrl || '/login',
    QR: config.qrUrl || '/api'

};



export {
    BASE_URLS
}