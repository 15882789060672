<template>
  <div class="container">
    <div class="mb-2">
      <ErrorMessage :error="errorCode"></ErrorMessage>
    </div>
    <div class="buttonwrapper">
      <pn-button @click="tryAgain">
        Försök igen
      </pn-button>
    </div>  
  </div>
</template>

<script>

  import ErrorMessage from '../components/ErrorMessage.vue'

  export default {
    components: {
      ErrorMessage
    },
    data() {
      return {
        errorCode: this.$route.query.error
      }
    },
    methods: {
      tryAgain() {
        this.$router.push({
          name: 'payment',
          params: {
            id: this.$route.params.id
          }
        });
      }
    }
  }

</script>

<style scoped>

  .container {
    max-width: 30em;
    margin: 0 auto;
    padding-top: 2rem;
  }

  .buttonwrapper {
    display: flex;
    justify-content: center;
    padding-bottom: 1em;
  }

  .pn-button {
    width: 90%;
    justify-self: center;
    margin: auto;
  }

  .mb-2 {
    margin-bottom: 2rem;
  }

</style>