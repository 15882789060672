const formatDate = (value, $t) => {
    if (value && typeof value === 'string' && value.includes('-')) {
        const [y, m, d] = value.split('-').map(x => parseInt(x, 10));
        const months = {
            0: $t('date.month.jan'),
            1: $t('date.month.feb'),
            2: $t('date.month.mar'),
            3: $t('date.month.apr'),
            4: $t('date.month.may'),
            5: $t('date.month.june'),
            6: $t('date.month.july'),
            7: $t('date.month.aug'),
            8: $t('date.month.sep'),
            9: $t('date.month.oct'),
            10: $t('date.month.nov'),
            11: $t('date.month.dec')
        };

        return `${d} ${months[m - 1]} ${y}`;
    }
    return '';
};
const calculateNextAvailableDate = (startStr, minStr, exceptions = []) => {
    const format = (date) => {
        const y = date.getFullYear();
        const m = date.getMonth() + 1;
        const d = date.getDate();
        return `${y}-${m < 10 ? '0' : ''}${m}-${d < 10 ? '0' : ''}${d}`;
    };

    const parse = (str) => {
        if (typeof str !== 'string') {
            console.error("Invalid date string:", str);
            throw new Error("Expected a valid date string in the format 'YYYY-MM-DD'");
        }

        const [year, month, date] = str.split('-').map(x => parseInt(x, 10));
        return new Date(year, month - 1, date);
    };

    const isValid = (d) => {
        if (d.getDay() === 0 || d.getDay() === 6) {
            return false;
        }
        if (Array.isArray(exceptions) && exceptions.indexOf(format(d)) !== -1) {
            return false;
        }
        return true;
    };

    let date = parse(startStr);
    let min = parse(minStr);

    if (date < min) {
        date = min;
    }

    let dir = -1;

    while (!isValid(date)) {
        if (dir === -1 && date.getTime() === min.getTime()) {
            dir = 1;
            date = min;
        }
        date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + dir);
    }

    return format(date);
};

export {formatDate, calculateNextAvailableDate}
