<template>
  <div class="optIn">
    <div class="container">
      <div class="optinText">
        <h3 v-if="!value" class="pn-m bold">{{ $t('optin.header') }}</h3>
        <h3 v-if="value" class="pn-m bold">{{ $t('optin.subheader') }}</h3>
        <span v-if="!value">{{ getOptInWelcomeText() }}</span>
        <div v-if="value" class="method">
          <pn-icon v-if="type == 'email'" symbol="envelope"></pn-icon>
          <pn-icon v-if="type == 'sms'" symbol="device-mobile"></pn-icon>
          <span>{{ value }}</span>
        </div>
      </div>
      <pn-button data-testid="optInStartButton" class="buttonwrapper" v-if="!value" @click="optInStartClickHandler">
        {{ $t('optin.start.normal') }}
      </pn-button>
      <pn-button v-if="value" class="clear" @click="optInStartClickHandler" variant="borderless" appearance="light">
        {{ $t('optin.start.redo') }}
      </pn-button>
    </div>
    <pn-modal :open="optInStatus > 0" @close="optInModalCloseHandler">
      <div class="center">
        <h2 v-if="optInStatus == 1">{{ $t('optin.method') }}</h2>
        <h2 v-if="optInStatus == 2 && optInMethod == 'email'">{{ $t('optin.email.header') }}</h2>
        <h2 v-if="optInStatus == 2 && optInMethod == 'sms'">{{ $t('optin.sms.header') }}</h2>
        <img src="../assets/optIn2.svg">
      </div>
      <div v-if="optInStatus == 1">
        <div class="radio-row" v-if="methodContains('email')">
          <pn-radio-button data-testid="optInEmailRadioButton" type="radio" radioid="optin-email" name="optin" value="email"
            @change="optInMethodChangeHandler" />
          <label for="optin-email">{{ $t('optin.email.option') }}</label>
        </div>
        <div class="radio-row" v-if="methodContains('sms')">
          <pn-radio-button data-testid="optInSmsRadioButton" type="radio" radioid="optin-sms" name="optin" value="sms"
            @change="optInMethodChangeHandler" />
          <label for="optin-sms">{{ $t('optin.sms.option') }}</label>
        </div>
      </div>
      <div v-if="optInStatus == 2 && optInMethod == 'email'">
        <div class="optin-input pb-2">
          <label>{{ $t('optin.email.label')}}</label>
          <input type="text" data-testid="emailInput" placeholder="" v-model="optInValueTemp" :readonly="optInLoading" />
        </div>
        <div class="optin-input">
          <label>{{ $t('optin.email.confirm') }}</label>
          <input type="text" data-testid="confirmEmailInput" placeholder="" v-model="optInValueConfirm" :readonly="optInLoading" />
        </div>
      </div>
      <div v-if="optInStatus == 2 && optInMethod == 'sms'">
        <div class="optin-input pb-2">
          <label>{{ $t('optin.sms.label') }}</label>
          <input type="text" data-testid="smsInput" placeholder="Ex. +46700600020" v-model="optInValueTemp" :readonly="optInLoading" />
        </div>
        <div class="optin-input">
          <label>{{ $t('optin.sms.confirm') }}</label>
          <input type="text" data-testid="confirmSmsInput" placeholder="Ex. +46700600020" v-model="optInValueConfirm" :readonly="optInLoading" />
        </div>
      </div>
      <ErrorMessage :error="error"></ErrorMessage>
      <div class="center mb-2 pt-1">
        <pn-progress-indicator :activeStep="optInStatus - 1" class="progress">
          <pn-progress-indicator-step></pn-progress-indicator-step>
          <pn-progress-indicator-step></pn-progress-indicator-step>
        </pn-progress-indicator>
      </div>
      <div class="spinner" v-if="optInLoading">
        <pn-spinner></pn-spinner>
      </div>
      <div class="buttonwrapper">
        <pn-button data-testid="optInNextButton" @click="optInMethodClickHandler" v-if="optInStatus == 1">
          {{ $t('optin.next') }}
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor"
              d="M19.7071 11.2929C20.0976 11.6834 20.0976 12.3166 19.7071 12.7071L13.7071 18.7071C13.3166 19.0976 12.6834 19.0976 12.2929 18.7071C11.9024 18.3166 11.9024 17.6834 12.2929 17.2929L16.5858 13L8 13C7.44771 13 7 12.5523 7 12C7 11.4477 7.44771 11 8 11L16.5858 11L12.2929 6.70711C11.9024 6.31658 11.9024 5.68342 12.2929 5.29289C12.6834 4.90237 13.3166 4.90237 13.7071 5.29289L19.7071 11.2929ZM5.01 13L5 13C4.44771 13 4 12.5523 4 12C4 11.4477 4.44771 11 5 11L5.01 11C5.56229 11 6.01 11.4477 6.01 12C6.01 12.5523 5.56229 13 5.01 13Z" />
          </svg>
        </pn-button>
        <pn-button data-testid="optInSaveButton" @click="optInSaveClickHandler" v-if="optInStatus == 2">
          {{ $t('optin.done') }}
        </pn-button>
        <div class="samtycke-text">
          {{ $t('optin.consent.part1') }}
          <br />
          {{ $t('optin.consent.part2', { recipient: paymentRecipient }) }}
        </div>
      </div>
    </pn-modal>
  </div>
</template>

<script>

import ErrorMessage from './ErrorMessage.vue';
import axios from "axios";
import { BASE_URLS } from '../services/urlService';

export default {
  props: ["paymentId", "methods", "paymentRecipient", "token"],
  data() {
    return {
      optInStatus: 0,
      optInMethod: "",
      optInValueTemp: "",
      optInValueConfirm: "",
      optInLoading: false,
      error: null,
      value: "",
      type: "",
    };
  },
  methods: {
    optInMethodChangeHandler(e) {
      this.optInMethod = e.target.value;
    },
    getRequestConfig() {
      if (!this.token) {
        return undefined;
      }
      return {
        headers: { 'Authorization': 'Bearer ' + this.token }
      };
    },
    methodContains(method) {
      return this.methods?.map(x => x.toLowerCase()).indexOf(method) != -1;

    },
    optInMethodClickHandler() {
      if (!this.optInMethod) {
        return;
      }
      this.optInStatus = 2;
    },
    optInModalCloseHandler() {
      this.optInStatus = 0;
    },
    optInStartClickHandler(e) {
      e.preventDefault();
      this.optInMethod = "";
      this.optInStatus = 1;
      this.optInValueTemp = "";
      this.optInValueConfirm = "";
      this.optInLoading = false;
      this.error = null;
    },
    optInSaveClickHandler() {
      this.error = null;
      if (!this.optInValueTemp) {
        this.error = 'MISSING_' + this.optInMethod.toUpperCase();
        return;
      }
      if (this.optInValueTemp !== this.optInValueConfirm) {
        this.error = 'CONFIRM_' + this.optInMethod.toUpperCase();
        return;
      }
      this.optInLoading = true;
      const key = this.optInMethod == "email" ? "email" : "phoneNumber";
      const call = axios.post(`${BASE_URLS.OPTIN}/optin`, {
        paymentId: this.paymentId,
        [key]: this.optInValueTemp
      }, this.getRequestConfig());
      call.then((resp) => {
        const prop = this.optInMethod == "email" ? "email" : "phone";
        this.optInStatus = 0;
        this.value = resp.data[prop];
        this.type = this.optInMethod;
      }).catch(err => {
        this.error = err.response.data.compositeFault.faults[0].faultCode;
      }).finally(() => {
        this.optInLoading = false;
      });
    },
    getOptInWelcomeText() {
      const translations = {
        'EMAIL': this.$t('optin.welcome.email'),
      };

      let methodsArray = this.methods.slice()
      if(this.methods.includes("AUTOGIRO")){
        methodsArray.splice(methodsArray.indexOf("AUTOGIRO"), 1)
      }

      const methodsText = (methodsArray || []).reduce((memo, method, index, arr) => {
        return memo + (index > 0 ? (index < arr.length - 1 ? ', ' :  (' ' + this.$t('optin.welcome.or') + ' ')) : '') + (translations[method] || method);
      }, '')
      return this.$t('optin.welcome.text', { methods: methodsText })
    }
  },
  components: { ErrorMessage }
}

</script>

<style scoped>
.optIn {
  margin: 4.5rem auto auto auto;
  width: 100%;
  border-radius: 0.5em;
  background-color: #e0f8ff;
}

.container {
  display: flex;
  flex-direction: column;
  line-height: normal;
  text-align: left;
  margin: 0 2em;
}

.samtycke-text {
  font-size: 0.75em;
  margin-bottom: 1em;
  margin-top: 1em;
}

.method {
  display: flex;
  align-items: center;
}

.method span {
  margin-left: 0.5rem;
}

.margin-bottom {
  margin-bottom: 2em;
}

.link {
  padding-bottom: 1em;
  font-weight: 600;
  text-decoration: none;
}

.buttonwrapper {
  margin: 1em 0;
}

pn-button {
  width: 100%;
}

.radio-row {
  display: flex;
  align-items: center;
  padding: 1rem 0;
}

.radio-row label {
  margin-left: 0.8rem;
}

.center {
  display: flex;
  align-items: center;
  flex-direction: column;

}

.progress {
  width: 7rem;
  font-size: 0.5rem;
  display: block;
}

.mb-2 {
  margin-bottom: 2rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.pt-1 {
  padding-top: 1rem;
}

.optin-input label {
  display: block;
  font-size: 1.4rem;
  color: #5E554A;
  padding-bottom: 1rem;

}

.optin-input input {
  width: 100%;
  padding: 1.3rem 1.2rem;
  border: 1px solid #5E554A;
  border-radius: 0.8rem;
  font-size: 1.6rem;
}

.optinText {
  text-align: left;
  padding-bottom: 1em;
  border-bottom: 1px solid #8eddf9;
  width: 100%;
}

pn-toast {
  width: 100%;
  margin-top: 2rem;
}
</style>
