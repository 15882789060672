const holidays = {
  "SE": [
    "2022-01-01",
    "2022-01-06",
    "2022-04-15",
    "2022-04-17",
    "2022-04-18",
    "2022-05-01",
    "2022-05-26",
    "2022-06-05",
    "2022-06-06",
    "2022-06-25",
    "2022-11-05",
    "2022-12-25",
    "2022-12-26",
    "2023-01-01",
    "2023-01-06",
    "2023-04-07",
    "2023-04-09",
    "2023-04-10",
    "2023-05-01",
    "2023-05-18",
    "2023-05-28",
    "2023-06-06",
    "2023-06-24",
    "2023-11-04",
    "2023-12-25",
    "2023-12-26",
    "2024-01-01",
    "2024-01-06",
    "2024-03-29",
    "2024-03-31",
    "2024-04-01",
    "2024-05-01",
    "2024-05-09",
    "2024-05-19",
    "2024-06-06",
    "2024-06-22",
    "2024-11-02",
    "2024-12-25",
    "2024-12-26",
    "2025-01-01",
    "2025-01-06",
    "2025-04-18",
    "2025-04-20",
    "2025-04-21",
    "2025-05-01",
    "2025-05-29",
    "2025-06-06",
    "2025-06-08",
    "2025-06-21",
    "2025-11-01",
    "2025-12-25",
    "2025-12-26",
    "2026-01-01",
    "2026-01-06",
    "2026-04-03",
    "2026-04-05",
    "2026-04-06",
    "2026-05-01",
    "2026-05-14",
    "2026-05-24",
    "2026-06-06",
    "2026-06-20",
    "2026-10-31",
    "2026-12-25",
    "2026-12-26",
    "2027-01-01",
    "2027-01-06",
    "2027-03-26",
    "2027-03-28",
    "2027-03-29",
    "2027-05-01",
    "2027-05-06",
    "2027-05-16",
    "2027-06-06",
    "2027-06-26",
    "2027-11-06",
    "2027-12-25",
    "2027-12-26",
    "2028-01-01",
    "2028-01-06",
    "2028-04-14",
    "2028-04-16",
    "2028-04-17",
    "2028-05-01",
    "2028-05-25",
    "2028-06-04",
    "2028-06-06",
    "2028-06-24",
    "2028-11-04",
    "2028-12-25",
    "2028-12-26",
    "2029-01-01",
    "2029-01-06",
    "2029-03-30",
    "2029-04-01",
    "2029-04-02",
    "2029-05-01",
    "2029-05-10",
    "2029-05-20",
    "2029-06-06",
    "2029-06-23",
    "2029-11-03",
    "2029-12-25",
    "2029-12-26",
    "2030-01-01",
    "2030-01-06",
    "2030-04-19",
    "2030-04-21",
    "2030-04-22",
    "2030-05-01",
    "2030-05-30",
    "2030-06-06",
    "2030-06-09",
    "2030-06-22",
    "2030-11-02",
    "2030-12-25",
    "2030-12-26"
  ],
  "DK": [
    "2022-01-01",
    "2022-04-14",
    "2022-04-15",
    "2022-04-17",
    "2022-04-18",
    "2022-05-01",
    "2022-05-13",
    "2022-05-26",
    "2022-06-05",
    "2022-06-05",
    "2022-06-06",
    "2022-12-25",
    "2022-12-26",
    "2023-01-01",
    "2023-04-06",
    "2023-04-07",
    "2023-04-09",
    "2023-04-10",
    "2023-05-01",
    "2023-05-05",
    "2023-05-18",
    "2023-05-28",
    "2023-05-29",
    "2023-06-05",
    "2023-12-25",
    "2023-12-26",
    "2024-01-01",
    "2024-03-28",
    "2024-03-29",
    "2024-03-31",
    "2024-04-01",
    "2024-04-26",
    "2024-05-01",
    "2024-05-09",
    "2024-05-19",
    "2024-05-20",
    "2024-06-05",
    "2024-12-25",
    "2024-12-26",
    "2025-01-01",
    "2025-04-17",
    "2025-04-18",
    "2025-04-20",
    "2025-04-21",
    "2025-05-01",
    "2025-05-16",
    "2025-05-29",
    "2025-06-05",
    "2025-06-08",
    "2025-06-09",
    "2025-12-25",
    "2025-12-26",
    "2026-01-01",
    "2026-04-02",
    "2026-04-03",
    "2026-04-05",
    "2026-04-06",
    "2026-05-01",
    "2026-05-01",
    "2026-05-14",
    "2026-05-24",
    "2026-05-25",
    "2026-06-05",
    "2026-12-25",
    "2026-12-26",
    "2027-01-01",
    "2027-03-25",
    "2027-03-26",
    "2027-03-28",
    "2027-03-29",
    "2027-04-23",
    "2027-05-01",
    "2027-05-06",
    "2027-05-16",
    "2027-05-17",
    "2027-06-05",
    "2027-12-25",
    "2027-12-26",
    "2028-01-01",
    "2028-04-13",
    "2028-04-14",
    "2028-04-16",
    "2028-04-17",
    "2028-05-01",
    "2028-05-12",
    "2028-05-25",
    "2028-06-04",
    "2028-06-05",
    "2028-06-05",
    "2028-12-25",
    "2028-12-26",
    "2029-01-01",
    "2029-03-29",
    "2029-03-30",
    "2029-04-01",
    "2029-04-02",
    "2029-04-27",
    "2029-05-01",
    "2029-05-10",
    "2029-05-20",
    "2029-05-21",
    "2029-06-05",
    "2029-12-25",
    "2029-12-26",
    "2030-01-01",
    "2030-04-18",
    "2030-04-19",
    "2030-04-21",
    "2030-04-22",
    "2030-05-01",
    "2030-05-17",
    "2030-05-30",
    "2030-06-05",
    "2030-06-09",
    "2030-06-10",
    "2030-12-25",
    "2030-12-26"
  ],
  "FI": [
    "2022-01-01",
    "2022-01-06",
    "2022-04-15",
    "2022-04-18",
    "2022-05-01",
    "2022-05-26",
    "2022-06-24",
    "2022-06-25",
    "2022-11-05",
    "2022-12-06",
    "2022-12-24",
    "2022-12-25",
    "2022-12-26",
    "2023-01-01",
    "2023-01-06",
    "2023-04-07",
    "2023-04-10",
    "2023-05-01",
    "2023-05-18",
    "2023-06-23",
    "2023-06-24",
    "2023-11-04",
    "2023-12-06",
    "2023-12-24",
    "2023-12-25",
    "2023-12-26",
    "2024-01-01",
    "2024-01-06",
    "2024-03-29",
    "2024-04-01",
    "2024-05-01",
    "2024-05-09",
    "2024-06-21",
    "2024-06-22",
    "2024-11-02",
    "2024-12-06",
    "2024-12-24",
    "2024-12-25",
    "2024-12-26",
    "2025-01-01",
    "2025-01-06",
    "2025-04-18",
    "2025-04-21",
    "2025-05-01",
    "2025-05-29",
    "2025-06-20",
    "2025-06-21",
    "2025-11-01",
    "2025-12-06",
    "2025-12-24",
    "2025-12-25",
    "2025-12-26",
    "2026-01-01",
    "2026-01-06",
    "2026-04-03",
    "2026-04-06",
    "2026-05-01",
    "2026-05-14",
    "2026-06-19",
    "2026-06-20",
    "2026-10-31",
    "2026-12-06",
    "2026-12-24",
    "2026-12-25",
    "2026-12-26",
    "2027-01-01",
    "2027-01-06",
    "2027-03-26",
    "2027-03-29",
    "2027-05-01",
    "2027-05-06",
    "2027-06-25",
    "2027-06-26",
    "2027-11-06",
    "2027-12-06",
    "2027-12-24",
    "2027-12-25",
    "2027-12-26",
    "2028-01-01",
    "2028-01-06",
    "2028-04-14",
    "2028-04-17",
    "2028-05-01",
    "2028-05-25",
    "2028-06-23",
    "2028-06-24",
    "2028-11-04",
    "2028-12-06",
    "2028-12-24",
    "2028-12-25",
    "2028-12-26",
    "2029-01-01",
    "2029-01-06",
    "2029-03-30",
    "2029-04-02",
    "2029-05-01",
    "2029-05-10",
    "2029-06-22",
    "2029-06-23",
    "2029-11-03",
    "2029-12-06",
    "2029-12-24",
    "2029-12-25",
    "2029-12-26",
    "2030-01-01",
    "2030-01-06",
    "2030-04-19",
    "2030-04-22",
    "2030-05-01",
    "2030-05-30",
    "2030-06-21",
    "2030-06-22",
    "2030-11-02",
    "2030-12-06",
    "2030-12-24",
    "2030-12-25",
    "2030-12-26"
  ],
  "NO": [
    "2022-01-01",
    "2022-04-14",
    "2022-04-15",
    "2022-04-17",
    "2022-04-18",
    "2022-05-01",
    "2022-05-17",
    "2022-05-26",
    "2022-06-05",
    "2022-06-06",
    "2022-12-25",
    "2022-12-26",
    "2023-01-01",
    "2023-04-06",
    "2023-04-07",
    "2023-04-09",
    "2023-04-10",
    "2023-05-01",
    "2023-05-17",
    "2023-05-18",
    "2023-05-28",
    "2023-05-29",
    "2023-12-25",
    "2023-12-26",
    "2024-01-01",
    "2024-03-28",
    "2024-03-29",
    "2024-03-31",
    "2024-04-01",
    "2024-05-01",
    "2024-05-09",
    "2024-05-17",
    "2024-05-19",
    "2024-05-20",
    "2024-12-25",
    "2024-12-26",
    "2025-01-01",
    "2025-04-17",
    "2025-04-18",
    "2025-04-20",
    "2025-04-21",
    "2025-05-01",
    "2025-05-17",
    "2025-05-29",
    "2025-06-08",
    "2025-06-09",
    "2025-12-25",
    "2025-12-26",
    "2026-01-01",
    "2026-04-02",
    "2026-04-03",
    "2026-04-05",
    "2026-04-06",
    "2026-05-01",
    "2026-05-14",
    "2026-05-17",
    "2026-05-24",
    "2026-05-25",
    "2026-12-25",
    "2026-12-26",
    "2027-01-01",
    "2027-03-25",
    "2027-03-26",
    "2027-03-28",
    "2027-03-29",
    "2027-05-01",
    "2027-05-06",
    "2027-05-16",
    "2027-05-17",
    "2027-05-17",
    "2027-12-25",
    "2027-12-26",
    "2028-01-01",
    "2028-04-13",
    "2028-04-14",
    "2028-04-16",
    "2028-04-17",
    "2028-05-01",
    "2028-05-17",
    "2028-05-25",
    "2028-06-04",
    "2028-06-05",
    "2028-12-25",
    "2028-12-26",
    "2029-01-01",
    "2029-03-29",
    "2029-03-30",
    "2029-04-01",
    "2029-04-02",
    "2029-05-01",
    "2029-05-10",
    "2029-05-17",
    "2029-05-20",
    "2029-05-21",
    "2029-12-25",
    "2029-12-26",
    "2030-01-01",
    "2030-04-18",
    "2030-04-19",
    "2030-04-21",
    "2030-04-22",
    "2030-05-01",
    "2030-05-17",
    "2030-05-30",
    "2030-06-09",
    "2030-06-10",
    "2030-12-25",
    "2030-12-26"
  ]
};

export { holidays };
