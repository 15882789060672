
const parse = (hash) => {

    if (!hash) {
        return {};
    }

    return hash.substring(1).split('&').reduce((memo, data) => {
        const [key, value] = data.split(/=(.*)/);
        if (!value) {
            return memo;
        }
        return {
            ...memo,
            [key]: decodeURIComponent(value)
        };
    }, {});
}

const stringify = (data) => {
    const result = Object.keys(data).reduce((memo, key) => {
        const value = data[key];
        if (!value) {
            return memo;
        }
        return [
            ...memo,
            `${key}=${encodeURIComponent(value)}`
        ];
    }, []).join('&');
    if (!result) {
        return null;
    }
    return '#' + result;
}

const set = (hash, key, value) => {
    const decoded = parse(hash);
    const updated = {
        ...decoded,
        [key]: value
    };
    return stringify(updated);
}

const get = (hash, key) => {
    const decoded = parse(hash);
    return decoded[key];
}

const remove = (hash, key) => {
    const decoded = parse(hash);
    const { [key]: _, ...updated } = decoded;
    return stringify(updated);
}

export default {
    get,
    set,
    remove
};