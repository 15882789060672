<template>
  <pn-toast v-if="message" appearance="warning" closable="false" icon="alert-exclamation-triangle">
    {{ message }}
  </pn-toast>
</template>

<script>

const errorMap = {
  INVALID_EMAIL: 'invalidEmail',
  INVALID_PHONE_NUMBER: 'invalidSms',
  MISSING_EMAIL: 'enterEmail',
  MISSING_SMS: 'enterSms',
  CONFIRM_EMAIL: 'confirmEmail',
  CONFIRM_SMS: 'confirmSms',
  USER_CANCELLED: 'userCancelled',
  AUTHENTICATION_ERROR: 'authenticationError',
  EXECUTION_DATE_NOT_BUSINESS_DAY: 'wrongExecutionDate',
  AM02:'tooLarge',
  AM06:'payNotAsSpecified',
  ACMT03:'payerNotEnrolled',
  VR01:'payerTooYoung',
  VR02:'payerNotEnrolledSSN'
};

const DEFAULT_MESSAGE = 'defaultMessage';

export default {
  props: [
    "error"
  ],
  computed: {
    message() {
      if (errorMap[this.error]) {
        return this.$t('error.messages.' + errorMap[this.error]);
      }
      return this.error && this.$t('error.messages.' + DEFAULT_MESSAGE) || null;
    }
  }
}

</script>

