<template>
    <div class="main">
        <div class="wrapper">
            <img class="warning" alt="warningsign" src="../assets/warning.svg" v-if="errorcode != 404">
            <h1 class="pn-xl" v-if="errorcode != 404 && errorcode < 1000">{{ $t('error.5xx.header') }}</h1>
            <h1 class="pn-xl" v-if="errorcode == 404">{{ $t('error.404.header') }}</h1>
            <h1 class="pn-xl" v-if="errorcode == 1001">{{ $t('error.1001.header') }}</h1>
            <div v-if="errorcode != 404 && errorcode < 1000">{{ $t('error.5xx.subheader') }}</div>
            <div v-if="errorcode == 1001">{{ $t('error.1001.subheader') }}</div>
        </div>
        <ul v-if="errorcode == 404">
            <li>{{ $t('error.404.missing') }}</li>
            <li>{{ $t('error.404.url') }}</li>
            <li>{{ $t('error.404.link') }}</li>
        </ul>
    
        <img class="lighthouse" alt="lighthouse" src="../assets/lighthouse.svg">

    </div>
</template>


<script>
    export default {
        props: ["errorcode"],

    }
</script>

<style scoped>
    .lighthouse {
        max-width: 100%;
    }
    .warning {
        display: inline-block;
        margin: 0 auto;
    }
    .wrapper {
        margin: 6em 0 4em;
        text-align: center;
    }

</style>